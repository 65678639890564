import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDocsState } from "../../../context/DocsStateContext";

const useStyles = makeStyles((theme) => ({
  imageRoot: {
    maxWidth: "calc(100% - 50px)", //padding * 2 + border * 2
    padding: 24,
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const docImage = {
  render: "DocImage",
  attributes: {
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
  },
};

export const docImageConfig = {
  tags: {
    image: docImage,
  },
};

export function DocImage({ src, alt }) {
  const classes = useStyles();
  const {
    state: { structureFile },
  } = useDocsState();

  return (
    <img
      className={classes.imageRoot}
      src={`https://raw.githubusercontent.com/fingoti/${structureFile}/main/${src}`}
      alt={alt}
    />
  );
}
