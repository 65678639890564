import { sanitizeUrl as braintreeSanitizeUrl } from "@braintree/sanitize-url"
import cssEscape from "css.escape"

export const createDeepLinkPath = (str) => typeof str == "string" || str instanceof String ? str.trim().replace(/\s/g, "%20") : ""

export const escapeDeepLinkPath = (str) => cssEscape(createDeepLinkPath(str).replace(/%20/g, "_"))

export function sanitizeUrl(url) {
    if (typeof url !== "string" || url === "") {
        return ""
    }
    return braintreeSanitizeUrl(url)
}

export function safeBuildUrl(url, specUrl, { selectedServer = "" } = {}) {
    try {
        return buildUrl(url, specUrl, { selectedServer })
    } catch {
        return undefined
    }
}

export function buildUrl(url, specUrl, { selectedServer = "" } = {}) {
    return new URL(url, window.location.href).href
}

export function isFunc(thing) {
    return typeof(thing) === "function"
  }