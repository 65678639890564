import { useEffect, useState, useCallback } from "react";
import { cdnUrl } from "../services/config";

export default function useGithubTree(structureFile) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [tree, setTree] = useState(undefined);

  const getJsonTree = useCallback(async () => {
    try {
      const result = await fetch(`${cdnUrl}/documentation/${structureFile}.json`);

      if (!result.ok) {
        throw new Error(
          `Response code does not indicate success - ${result.status} - ${result.statusText}`
        );
      }

      const json = await result.json();

      console.log(json);

      setTree(json);
    } catch (e) {
      setError(
        "Something went wrong fetching the documentation, please try again"
      );
    } finally {
      setLoading(false);
    }
  }, [structureFile]);

  useEffect(() => {
    getJsonTree();
  }, [getJsonTree]);

  return {
    loading,
    error,
    tree,
  };
}
