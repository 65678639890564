import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  docsStateReducerActions,
  useDocsState,
} from "../../../context/DocsStateContext";

const useStyles = makeStyles((theme) => ({
  docLink: {
    textDecoration: "underline",
    color: theme.palette.greyTwo.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const docLink = {
  render: "DocLink",
  attributes: {
    href: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};

export const docLinkConfig = {
  tags: {
    link: docLink,
  },
};

export function DocLink({ href, title, children }) {
  const classes = useStyles();
  const { dispatch } = useDocsState();

  if (href.startsWith("http")) {
    return (
      <a href={href} className={classes.docLink}>
        {children}
      </a>
    );
  } else {
    return (
      <span
        onClick={() =>
          dispatch({
            type: docsStateReducerActions.inlineLinkClick,
            payload: { path: href },
          })
        }
        className={classes.docLink}
      >
        {children}
      </span>
    );
  }
}
