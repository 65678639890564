import React from "react";
import SwaggerUI from "swagger-ui-react";

import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CustomOperationSummary from "./swaggerComponents/CustomOperationSummary";
import CustomOperationSummaryPath from "./swaggerComponents/CustomOperationSummaryPath";
import CustomOperationTag from "./swaggerComponents/CustomOperationTag";

import { apiUrl } from "../../../services/config";
import "swagger-ui-react/swagger-ui.css";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".swagger-ui .btn.authorize, .swagger-ui .btn.authorize svg": {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
    ".swagger-ui .responses-table": {
      display: "block",
    },
    ".swagger-ui .response-col_links": {
      display: "none",
    },
    ".swagger-ui .renderedMarkdown p": {
      margin: 0,
    },
    ".swagger-ui .wrapper": {
      padding: 0,
    },
    ".swagger-ui": {
      width: "100%",
      fontFamily: "'Work Sans', sans-serif !important",
    },
    ".information-container": {
      display: "none",
    },
    ".scheme-container": {
      padding: "0 !important",
      boxShadow: "none !important",
    },
    "*": {
      fontFamily: "'Work Sans', sans-serif !important",
    },
  },
}))

const renderSwagger = {
  render: "RenderSwagger",
};

export const renderSwaggerConfig = {
  tags: {
    renderSwagger,
  },
};

export function RenderSwagger() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const swaggerUrl = `${apiUrl.substring(
    0,
    apiUrl.length - 3
  )}/swagger/v1/swagger.json`;

  const CustomSwaggerComponents = () => ({
    wrapComponents: {
      OperationTag: (Original, system) => (props) => {
        if (isDesktop) {
          return <Original {...props} />;
        } else {
          return <CustomOperationTag {...props} />;
        }
      },
      OperationSummaryPath: (Original, system) => (props) => {
        if (isDesktop) {
          return <Original {...props} />;
        } else {
          return <CustomOperationSummaryPath {...props} />;
        }
      },
      OperationSummary: (Original, system) => (props) => {
        if (isDesktop) {
          return <Original {...props} />;
        } else {
          return <CustomOperationSummary {...props} />;
        }
      },
    },
  });

  return (
    <>
      <SwaggerUI
        url={swaggerUrl}
        defaultModelsExpandDepth={-1}
        docExpansion={"none"}
        plugins={[CustomSwaggerComponents()]}
      />
    </>
  );
}
