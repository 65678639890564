const envVars = process.env;

export let apiUrl = `${envVars.REACT_APP_RESOURCE_API_URL}/${envVars.REACT_APP_RESOURCE_API_VERSION}`;
export let authUrl = `${envVars.REACT_APP_AUTH_API_URL}/${envVars.REACT_APP_AUTH_API_VERSION}`;
export let cdnUrl = envVars.REACT_APP_CDN_URL;
export let webUrl = envVars.REACT_APP_WEB_URL;
export let portalUrl = envVars.REACT_APP_PORTAL_URL;
export let accountUrl = envVars.REACT_APP_ACCOUNT_URL;
export let developerUrl = envVars.REACT_APP_DEVELOPER_URL;
export let storeUrl = envVars.REACT_APP_STORE_URL;
export let cookieSettings;

let envString = envVars.REACT_APP_ENVIROMENT_STRING;

console.log("RUNNING ENVIROMENT", envString);

const getExpiry = () => {
    var expiry = new Date();
    expiry.setDate(expiry.getDate() + 7);
    return expiry;
}

if(envString === "LOCAL") {
    cookieSettings = () => { return ({ path: '/', expires: getExpiry() }) };
} else if (envString === "DEV") {
    cookieSettings = () => { return ({ domain: '.fingoti.net', path: '/', secure: true, sameSite: 'strict', expires: getExpiry() }) };
} else {
    cookieSettings = () => { return ({ domain: '.fingoti.com', path: '/', secure: true, sameSite: 'strict', expires: getExpiry() }) };
}