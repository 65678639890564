import React, { useState } from "react";

import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import TreeView from "@material-ui/lab/TreeView";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import {
  useDocsState,
  docsStateReducerActions,
} from "../../context/DocsStateContext";
import GithubTreeItem from "../shared/GitHubTreeItem";

const smDrawerWidth = "85%";
const mdDrawerWidth = "70%";

const useStyles = makeStyles((theme) => ({
  tabDrawer: {
    width: smDrawerWidth,
    [theme.breakpoints.only("sm")]: {
      width: mdDrawerWidth,
    },
  },
  backdropRoot: {
    zIndex: 100,
  },
  drawerPaper: {
    height: `calc(100% - ${theme.spacing(11)}px)`,
    top: theme.spacing(11),
    width: smDrawerWidth,
    [theme.breakpoints.only("sm")]: {
      width: mdDrawerWidth,
    },
  },
  drawerBack: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "end",
  },
  mobileExpand: {
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: theme.spacing(14) + theme.spacing(1) / 2,
    borderRadius: theme.spacing(1),
    left: "-8px",
  },
  tree: {
    borderRight: `1px solid ${theme.palette.greyFour.main}`,
    "& .MuiCollapse-wrapperInner > li:first-child": {
      paddingTop: 16,
    }
  },
  sticky: {
    position: "sticky",
    top: 112,
  },
}));

export default function DocTreeView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    state: {
      expanded,
      selected,
      tree: { data, loading },
    },
    dispatch,
  } = useDocsState();


  const renderTree = (nodes, level = 0, indexArr = []) => {
    return (
      <GithubTreeItem
        level={level}
        onClick={() => {
          dispatch({
            type: docsStateReducerActions.inlineLinkClick,
            payload: {
              path: nodes.path,
            },
          });
          if (nodes.download_url && isMobile) {
            setOpen(false);
          }
        }}
        mdURL={nodes.download_url}
        label={nodes.name}
        nodeId={`${nodes.index}`}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node, i) =>
              renderTree(node, level + 1, [...indexArr, i])
            )
          : null}
      </GithubTreeItem>
    );
  };

  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <Hidden mdUp>
          <div className={classes.mobileExpand}>
            <IconButton
              style={{ color: "#FFF", paddingRight: 0 }}
              onClick={() => setOpen(true)}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Drawer
            anchor="left"
            variant="permenant"
            className={classes.tabDrawer}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{
              BackdropProps: {
                classes: {
                  root: classes.backdropRoot,
                },
                onClick: () => setOpen(false),
              },
            }}
            open={open}
          >
            <div className={classes.drawerBack}>
              <IconButton onClick={() => setOpen(false)} style={{ padding: 4 }}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
            >
              {data.children.map((branch, i) => renderTree(branch, undefined, [i]))}
            </TreeView>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Grid item md={2} className={classes.tree}>
            <TreeView
              className={classes.sticky}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
            >
              {data.children.map((branch, i) => renderTree(branch, undefined, [i]))}
            </TreeView>
          </Grid>
        </Hidden>
      </>
    );
  }
}
