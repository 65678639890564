import React, { useState, useEffect } from "react";
import {
  useRouteMatch,
  useHistory,
  Link as RouterLink,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {
  FingotiLoading,
  FingotiMarkdown,
  FingotiTabs,
  FingotiHeader,
  FingotiButton,
} from "@fingoti/components";
import { Tab } from "@material-ui/core";
import { SDKMDComponents } from "./SDKMDComponents";

import CodeIcon from "@material-ui/icons/Code";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "10%",
  },
  tab: {
    width: "100%",
  },
  tabTitle: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  iconSpacing: {
    marginRight: theme.spacing(2),
    fontSize: "3rem",
  },
  tabRoot: {
    minWidth: "unset",
  },
  tabLabelRight: {
    alignItems: "unset",
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  boxPadding: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  "@global": {
    "p > code, li > code, pre": {
      backgroundColor: "#EBEBEB",
      padding: "0.5em 0.5em",
      borderRadius: 4,
    },
    "p > code, li > code": {
      padding: "0.25em 0.5em",
    },
  },
}));

const TabPanel = (props) => {
  const { passClass, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className={passClass}>{children}</div>}
    </div>
  );
};

const tabProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const RouterTab = (props) => <Tab component={RouterLink} {...props} />;

export const SDKPython = ({ activeMenu }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();
  const [value, setValue] = useState(activeMenu);

  useEffect(() => {
    setValue(activeMenu);
  }, []);

  const handleChange = (event, newValue) => {
    let isTwoPartPath = match.path.indexOf("/", 1);
    let baseRoute = "/sdk/python";

    if (isTwoPartPath !== -1) {
      //Path is /foo/bar, need to get rid of the /bar
      baseRoute = match.path.slice(0, isTwoPartPath);
    }

    switch (newValue) {
      case 0:
        history.push(`${baseRoute}`);
        break;
      default:
        console.log("not a valid value");
        break;
    }
    setValue(newValue);
  };

  const actionButtons = () => (
    <FingotiButton
      onClick={() =>
        window.open("https://github.com/fingoti/sdk-python", "_blank")
      }
      color="primary"
      light
    >
      View on GitHub
    </FingotiButton>
  );

  const breadcrumbs = [
    { text: "SDK", link: "/sdk" },
    { text: "Python", link: "" },
  ];

  return value === null ? (
    <FingotiLoading />
  ) : (
    <div className={classes.root}>
      <FingotiTabs
        value={value}
        onChange={handleChange}
        aria-label="python sdk documentation tabs"
      >
        <RouterTab
          to={"/sdk/python"}
          classes={{ wrapper: classes.tabLabelRight, root: classes.tabRoot }}
          label="Getting Started"
          {...tabProps(0)}
        />
      </FingotiTabs>

      <TabPanel
        passClass={classes.boxPadding}
        className={classes.tab}
        value={value}
        index={0}
      >
        <FingotiHeader
          breadcrumbs={breadcrumbs}
          sectionIcon={CodeIcon}
          actionButtons={actionButtons()}
        />
        <FingotiMarkdown
          path="/documentation/developer/sdk/python/getting_started.md"
          components={SDKMDComponents}
        />
      </TabPanel>
    </div>
  );
};
