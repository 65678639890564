import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import FingotiNav from '@fingoti/nav';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';

import { useProfileState } from '../context/ProfileContext';
import { apiService } from '../services/api.service';
import { authService } from '../services/auth.service';
import { cookieSettings } from '../services/config';
import { ProfileData } from '../context/ProfileData';
import * as appConfig from '../services/config';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        padding: theme.spacing(1),
        margin: 0,
        [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(100% - 114px);',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.spacing(3),
        }
    },

}))

const Layout = ({ children }) => {

    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const profileData = ProfileData();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies, setCookie, removeCookie] = useCookies();

    const handleSideNavClick = (link) => {
        history.push(link);
    }

    const handleBackClick = () => {
        history.goBack();
    }

    const handleSwitchOrganisation = (orgId) => {
        authService.getAppToken(orgId)
            .then(() => {
                removeCookie("Partner", cookieSettings());
                profileData.getData();
                enqueueSnackbar("Successfully switched organisation", { variant: 'success' })
            })
            .catch(() => {
                enqueueSnackbar("Failed to swtich organisation", { variant: 'error' })
            })
    }

    return (
        <React.Fragment>
            <FingotiNav
                onSideNavClick={handleSideNavClick}
                onBackClick={handleBackClick}
                currentApp="documentation"
                useProfileState={useProfileState}
                handleSwitchOrganisation={handleSwitchOrganisation}
                apiService={apiService}
                config={appConfig}
            />
            <Container className={classes.root}>
                {children}
            </Container>
        </React.Fragment>
    );
}

export default Layout;
