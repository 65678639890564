import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  code: {
    borderRadius: theme.spacing(1) / 2,
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(1) / 2}px`,
    background: theme.palette.greyFour.main,
    fontFamily: 'monospace',
  },
}));

const code = {
  render: "InlineCode",
  attributes: {
    content: {
      type: String,
    },
  },
};

export const codeConfig = {
  tags: {
    code,
  },
};

export function InlineCode({ content }) {
  const classes = useStyles();

  return (
    <span className={classes.code}>
      {content}
    </span>
  );
}
