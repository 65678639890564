import { useState } from "react";
import { createContext, useContext } from "react";

const initalState = "Pebl";

export const ProductLinePrefContext = createContext();

export const ProductLinePrefContextProvider = ({ children }) => {
  const [productLine, setProductLine] = useState(initalState);

  return (
    <ProductLinePrefContext.Provider value={{ productLine, setProductLine }}>
      {children}
    </ProductLinePrefContext.Provider>
  );
};

export const useProductLinePref = () => {
  const ctx = useContext(ProductLinePrefContext);

  if (!ctx) {
    throw new Error("useProductLinePref must be used inside a provider");
  }

  return ctx;
};
