import React, { useEffect, useState } from "react";
import Markdoc from "@markdoc/markdoc";

import { FingotiLoading } from "@fingoti/components";

export const FingotiMarkdoc = ({ path, components, transformConfig }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [content, setContent] = useState(undefined);

  useEffect(() => {
    fetch(path)
      .then((res) => {
        if (res.ok) return res.text();
        else throw Error("Failed to retrieve documentation file");
      })
      .then((content) => {
        setContent(
          Markdoc.transform(Markdoc.parse(content), {
            ...transformConfig,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [path, transformConfig]);

  if (loading) {
    return <FingotiLoading />;
  } else if (error) {
    return error;
  } else {
    return Markdoc.renderers.react(content, React, {
      components: {
        ...components,
      },
    });
  }
};
