import { useSnackbar } from "notistack";
import { apiService } from "../services/api.service";
import { useProfileDispatch } from "./ProfileContext";

export const ProfileData = () => {
  const dispatch = useProfileDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    dispatch({ type: "LOADING" });
    console.log("Starting profile context fill...");
    Promise.all([
      getUser(),
      getOrganisation(),
      getRoles(),
      getUserOrganisations(),
      getOrganisationAddresses(),
      getUsers(),
    ])
      .then(() => {
        dispatch({ type: "LOGGED_IN" });
        dispatch({ type: "LOADED" });
      })
      .catch((error) => {
        //On web/developer only - no need to force login
        // dispatch({ type: 'ERROR' });
        // enqueueSnackbar(error, { variant: 'error' });
        console.log("Unable to get user profile data", error);
        dispatch({ type: "LOGOUT" });
        dispatch({ type: "LOADED" });
      });
  };

  const getUser = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/user")
        .then((userData) => {
          delete userData.success;
          dispatch({ type: "ADD_USER", payload: userData });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getUsers = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/organisation/users")
        .then((usersData) => {
          delete usersData.success;
          dispatch({ type: "ADD_USERS", payload: usersData.users });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getOrganisation = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/organisation")
        .then((organisationData) => {
          delete organisationData.success;
          dispatch({ type: "ADD_ORGANISATION", payload: organisationData });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getRoles = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/organisation/roles")
        .then((rolesData) => {
          delete rolesData.success;
          dispatch({ type: "ADD_ROLES", payload: rolesData.roles });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getUserOrganisations = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/user/organisations")
        .then((organisationsData) => {
          delete organisationsData.success;
          dispatch({
            type: "ADD_ORGANISATIONS",
            payload: organisationsData.organisations,
          });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getOrganisationAddresses = () => {
    return new Promise((resolve, reject) => {
      apiService
        .getData("/organisation/addresses")
        .then((addressesData) => {
          delete addressesData.success;
          dispatch({ type: "ADD_ADDRESSES", payload: addressesData.addresses });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getData: getData,
  };
};
