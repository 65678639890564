import { Tag } from "@markdoc/markdoc";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  calloutRoot: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: "16px 0px 16px 12px",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const callout = {
  render: "Callout",
  transform(node, config) {
    const labels = node.transformChildren(config);

    let transformed = [];

    labels.forEach(() => {
      transformed.push(new Tag("Fragment", {}, labels[0].children));
    });

    return new Tag(this.render, { labels }, transformed);
  },
};

export const calloutConfig = {
  tags: {
    callout,
  },
};

export function Callout({ children }) {
  const classes = useStyles();

  return <div className={classes.calloutRoot}>{children}</div>;
}
