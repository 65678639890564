import { useState, createContext, useContext } from "react";
import { Tag } from "@markdoc/markdoc";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { default as MUITabs } from "@material-ui/core/Tabs";
import { default as MUITab } from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabsRoot: {
    borderRadius: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 2}px 0 0`,
    border: `1px solid ${theme.palette.primary.light}`,
    borderBottom: `1px solid white`,
    backgroundColor: theme.palette.primary.lighter,
  },
  tabRoot: {
    borderRadius: `0 0 ${theme.spacing(1) / 2}px ${theme.spacing(1) / 2}px`,
    borderTop: `1px solid white`,
    border: `1px solid ${theme.palette.primary.light}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tabSelected: {
    backgroundColor: `rgba(255, 140, 35, 0.2)`,
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.light,
  },
  tabsSelect: {
    width: "100%",
  },
}));

const TabContext = createContext();

const tabs = {
  render: "Tabs",
  attributes: {},
  transform(node, config) {
    const labels = node
      .transformChildren(config)
      .filter((child) => child && child.name === "Tab")
      .map((tab) => (typeof tab === "object" ? tab.attributes.label : null));

    return new Tag(this.render, { labels }, node.transformChildren(config));
  },
};

const tab = {
  render: "Tab",
  attributes: {
    label: {
      type: String,
    },
  },
};

export const tabsConfig = {
  tags: {
    tabs,
    tab,
  },
};

export function Tabs({ labels, children }) {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(labels[0]);

  return (
    <TabContext.Provider value={currentTab}>
      <Hidden smDown>
        <div className={classes.tabsContainer}>
          <MUITabs
            classes={{
              root: classes.tabsRoot,
              selected: classes.tabSelected,
              indicator: classes.tabIndicator,
            }}
            onChange={(_, tab) => setCurrentTab(tab)}
            value={currentTab}
          >
            {labels.map((label) => (
              <MUITab
                label={label}
                value={label}
                classes={{ selected: classes.tabSelected }}
              />
            ))}
          </MUITabs>
          {children}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.tabsContainer}>
          <div className={classes.tabsRoot}>
            <Select
              className={classes.tabsSelect}
              variant="outlined"
              value={currentTab}
              onChange={(event) => setCurrentTab(event.target.value)}
            >
              {labels.map((label) => (
                <MenuItem value={label} key={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
          {children}
        </div>
      </Hidden>
    </TabContext.Provider>
  );
}

export function Tab({ label, children }) {
  const classes = useStyles();
  const currentTab = useContext(TabContext);

  if (label !== currentTab) {
    return null;
  }

  return <div className={classes.tabRoot}>{children}</div>;
}
