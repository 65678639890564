import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { FingotiButton, FingotiLoading } from "@fingoti/components";

import DevicesIcon from "@material-ui/icons/Devices";
import StorageIcon from "@material-ui/icons/Storage";
import CodeIcon from "@material-ui/icons/Code";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';


import { Image } from "../Image";
import FingotiLogo from "../../icons/FingotiLogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
  },
  imageContent: {
    zIndex: 100,
    position: "absolute",
    top: theme.spacing(2),
    width: "90%",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "unset",
      width: "90%",
      top: theme.spacing(14),
    },
    [theme.breakpoints.up(1367)]: {
      top: theme.spacing(16),
    },
    [theme.breakpoints.up(1921)]: {
      width: "82%",
      top: theme.spacing(18),
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "right",
    padding: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
  },
  fingotiLogo: {
    [theme.breakpoints.up("xs")]: {
      width: "25%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "16%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "12%",
    },
    [theme.breakpoints.up(1921)]: {
      width: "10%",
    },
  },
  button: {
    width: "15%",
  },
  header: {
    fontSize: "2rem",
    color: theme.palette.greyOne.main,
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: "3rem",
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [thisLoading, setThisLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      {thisLoading ? (
        <FingotiLoading />
      ) : (
        <>
          <div>
            <Image
              alt="pebl"
              src="Breadboard.jpg"
              style={{
                marginTop: -24,
                marginLeft: -24,
                marginRight: -24,
                width: "calc(100% + 48px)",
              }}
            />
            {/* <Grid 
                            container 
                            className={classes.imageContent}
                            style={{
                                marginTop: -24
                            }}
                        >
                            <Grid item xs={12} className={classes.imageWrapper}>
                                <img src={FingotiLogo} className={classes.fingotiLogo} />
                            </Grid>
                            <Grid item xs={12} className={classes.imageWrapper}>
                                <Typography variant="h2">
                                    Fingoti Limited
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.imageWrapper}>
                                <Typography variant="h4">
                                    Official documentation for Fingoti products
                                </Typography>
                            </Grid>
                        </Grid> */}
          </div>
          <Grid container className={classes.root} spacing={5}>
            <Grid item xs={12} md={4} className={classes.wrapper}>
              <Grid container onClick={() => history.push("/documentation")}>
                <span className={classes.header}>
                  <DescriptionOutlinedIcon className={classes.icon} />
                  Documentation
                </span>
                <Typography variant="h6">
                  Comprehensive tutorials on how to use the Fingoti Cloud and devices.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className={classes.wrapper}>
              <Grid container onClick={() => history.push("/reference")}>
                <span className={classes.header}>
                  <SubjectOutlinedIcon className={classes.icon} /> Reference
                </span>
                <Typography variant="h6">
                  Developer references for the Fingoti hardware, device commands, and API.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className={classes.wrapper}>
              <Grid container onClick={() => history.push("/sdk")}>
                <span className={classes.header}>
                  <CodeIcon className={classes.icon} /> SDK
                </span>
                <Typography variant="h6">
                  Communicate with Fingoti devices in a code-first approach, using software development kits.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};
