import JSONPretty from "react-json-pretty";
import makeStyles from "@material-ui/core/styles/makeStyles";

import "./jsonColours.css";

const useStyles = makeStyles((theme) => ({
  code: {
    width: "fit-content",
    borderRadius: theme.spacing(1) / 2,
    padding: theme.spacing(1) / 2,
    background: theme.palette.greyFour.main,
    fontFamily: "monospace",
  },
}));

const json = {
  render: "JSON",
  attributes: {
    language: {
      type: String,
    },
  },
};

export const jsonConfig = {
  tags: {
    fence: json,
  },
};

export function JSON({ children, language }) {
  const classes = useStyles();
  
  return language === "json" ? (
    <JSONPretty data={children} />
  ) : (
    <pre className={classes.code}>{children}</pre>
  );
}
