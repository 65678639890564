import clsx from "clsx";
import { TreeItem } from "@material-ui/lab";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  treeItemRoot: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  labelLevel0: {
    backgroundColor: "#FFF !important",
    color: theme.palette.text.primary,
  },
  labelLevel1: {
    backgroundColor: "#FFF !important",
    color: theme.palette.greyOne.main,
  },
  labelLevel2: {
    backgroundColor: "#FFF !important",
    color: theme.palette.greyTwo.main
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: "#FFF",
    "& > div > div": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

export default function GithubTreeItem({
  children,
  onClick,
  mdURL,
  label,
  level,
  nodeId,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = () => {
    onClick(mdURL);
  };

  const getStyles = (level) => {
    switch (level) {
      case 0:
        return classes.labelLevel0
      case 1:
        return classes.labelLevel1
      case 2:
        return classes.labelLevel2
      default:
        return classes.labelLevel0
    }
  };

  return (
    <TreeItem
      onLabelClick={handleClick}
      onIconClick={handleClick}
      label={label}
      nodeId={nodeId}
      classes={{
        root: classes.treeItemRoot,
        selected: classes.selected,
        label: getStyles(level)
      }}
    >
      {children}
    </TreeItem>
  );
}
