import Alert from "@material-ui/lab/Alert";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "fit-content",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& > .MuiAlert-message > p": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& + .MuiAlert-root": {
      marginTop: 12,
    },
  },
}));

const contextualCallout = {
  render: "ContextualCallout",
  attributes: {
    severity: {
      type: String,
    },
  },
};

export const contextualCalloutConfig = {
  tags: {
    contextualCallout: contextualCallout,
  },
};

export function ContextualCallout({ severity, children }) {
  const classes = useStyles();

  return (
    <Alert severity={severity} className={classes.content}>
      {children}
    </Alert>
  );
}
