import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const SDKMDComponents = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        children={String(children).replace(/\n$/, "")}
        language={match[1]}
        style={stackoverflowLight}
        customStyle={{ background: '#EBEBEB' }}
        PreTag="div"
        {...props}
      />
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};
