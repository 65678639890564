import { Tag } from "@markdoc/markdoc";
import { useProductLinePref } from "../../../context/ProductLinePrefContext";

const productLineTabs = {
  render: "ProductLineTabs",
  attributes: {},
  transform(node, config) {
    const labels = node
      .transformChildren(config)
      .filter((child) => child && child.name === "ProductLineTab")
      .map((tab) => (typeof tab === "object" ? tab.attributes.label : null));

    return new Tag(this.render, { labels }, node.transformChildren(config));
  },
};

const productLineTab = {
  render: "ProductLineTab",
  attributes: {
    label: {
      type: String,
    },
  },
};

export const productLineTabsConfig = {
  tags: {
    productLineTabs,
    productLineTab,
  },
};

export function ProductLineTabs({ labels, children }) {
  const { productLine, setProductLine } = useProductLinePref();

  return (
    <>
      <ul role="tablist">
        {labels.map((label) => (
          <li key={label}>
            <button
              role="tab"
              aria-selected={label === productLine}
              onClick={() => setProductLine(label)}
            >
              {label}
            </button>
          </li>
        ))}
      </ul>
      {children}
    </>
  );
}

export function ProductLineTab({ label, children }) {
  const { productLine } = useProductLinePref();

  if (label !== productLine) {
    return null;
  }

  return <div style={{ color: "red" }}>{children}</div>;
}
