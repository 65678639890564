import { authService, refreshLock } from "./auth.service";
import { handleResponse } from "./response.service";
import Queue from "./queue.service";
import { apiUrl, accountUrl } from "./config";

async function getData(path) {
  console.log("getting data from: " + path);

  let requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authService.authHeader(),
    },
  };

  if (path.includes("token=")) {
    requestOptions.headers = { "Content-Type": "application/json" };
  }

  let request = new Request(apiUrl + path, requestOptions);

  return new Promise((resolve, reject) => {
    callAPI(request)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function patchData(path, bodyData) {
  console.log("patch data to: " + path);

  let requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...authService.authHeader(),
    },
    body: JSON.stringify(bodyData),
  };

  if (path.includes("token=")) {
    requestOptions.headers = { "Content-Type": "application/json" };
  }

  let request = new Request(apiUrl + path, requestOptions);

  return new Promise((resolve, reject) => {
    callAPI(request)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function postData(path, bodyData) {
  console.log("posting data to: " + path);

  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authService.authHeader(),
    },
    body: JSON.stringify(bodyData),
  };

  if (path.includes("token=")) {
    requestOptions.headers = { "Content-Type": "application/json" };
  }

  let request = new Request(apiUrl + path, requestOptions);

  return new Promise((resolve, reject) => {
    callAPI(request)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function postFormData(path, formData) {
  console.log("posting form data to: " + path);

  let requestOptions = {
    method: "POST",
    headers: { ...authService.authHeader() },
    body: formData,
  };

  let request = new Request(apiUrl + path, requestOptions);

  return new Promise((resolve, reject) => {
    callAPI(request)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function deleteData(path) {
  console.log("posting data to: " + path);

  let requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authService.authHeader(),
    },
  };

  if (path.includes("token=")) {
    requestOptions.headers = { "Content-Type": "application/json" };
  }

  let request = new Request(apiUrl + path, requestOptions);

  return new Promise((resolve, reject) => {
    callAPI(request)
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

const callAPI = (request) => {
  if (request.headers.has("Authorization")) {
    request.headers.set(
      "Authorization",
      authService.authHeader().Authorization
    );
  }

  let requestCopy = request.clone();

  return new Promise((resolve, reject) => {
    fetch(request)
      .then(handleResponse)
      .then((data) => {
        return resolve(data);
      })
      .catch((error) => {
        console.log("whole error", error);
        if (error.retry) {
          //Q Promise for later
          Queue.enqueue(() =>
            callAPI(requestCopy)
              .then((data) => {
                return resolve(data);
              })
              .catch((error) => {
                return reject(error.error);
              })
          );
          //Try and get a new app token
          if (!refreshLock) {
            authService
              .getAppToken()
              .then(() => {
                Queue.dequeue();
              })
              .catch((error) => {
                //If we got a 401 from auth api, ask user to login
                console.log("auth error", error);
                //On web/developer only - no need to force login
                // if(error.requireAuth) {
                //     window.location.assign(accountUrl);
                // }
                return reject(error.error);
              });
          }
        } else {
          console.log("non retry error");
          return reject(error.error);
        }
      });
  });
};

export const apiService = {
  getData,
  patchData,
  postData,
  postFormData,
  deleteData,
  callAPI,
};
