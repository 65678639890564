import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    image: {
        zIndex: 1,
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',

        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(12),
        },
        
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6),
        },
        
        "&:not(:first-child)": {
            marginTop: theme.spacing(12),
        }
      },
    
  }))

export const Image = ({ src, alt, ...rest }) => {
    const classes = useStyles();

    return (
        <img alt={alt} className={classes.image} src={"https://cdn.fingoti.com/images/static/" + src} {...rest} />
    );
}