import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  deviceBadge: {
    color: theme.palette.greyTwo.main,
    fontSize: 14,
    textTransform: 'uppercase',
    padding: '4px 8px',
    borderRadius: 4,
    marginLeft: theme.spacing(1) / 2,
    backgroundColor: `rgba(255, 140, 35, 0.4)`,
  },
}));

const deviceBadge = {
  render: "DeviceBadge",
  attributes: {
    device: {
      type: String,
    },
  },
};

export const deviceBadgeConfig = {
  tags: {
    deviceBadge,
  },
};

export function DeviceBadge({ device }) {
  const classes = useStyles();

  return (
    <span
      className={classes.deviceBadge}
    >
      {device}
    </span>
  );
}
