import { useState, useRef } from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  infoHoverRoot: {
    display: "inline-block",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    background: theme.palette.primary.lighter,
    padding: theme.spacing(1),
    pointerEvents: "auto",
  },
}));

const infoHover = {
  render: "InfoHover",
};

export const infoHoverConfig = {
  tags: {
    infoHover,
  },
};

export function InfoHover({ children }) {
  const classes = useStyles();
  const popoverAnchor = useRef(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.infoHoverRoot}>
      <InfoOutlinedIcon
        ref={popoverAnchor}
        aria-owns={open ? "info-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ fontSize: 16 }}
      />
      <Popover
        id="info-popover"
        open={open}
        anchorEl={popoverAnchor.current}
        className={classes.popover}
        classes={{ paper: classes.popoverContent }}
        style={{ zIndex: 1500 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // onClose={handlePopoverClose}
        // disableRestoreFocus
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <Typography>{children}</Typography>
      </Popover>
    </div>
  );
}
