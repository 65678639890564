import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  badge: {
    lineHeight: 2,
    borderRadius: theme.spacing(1) / 2,
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(1) / 1.5,
    paddingRight: theme.spacing(1) / 1.5,
  },
}));

const badge = {
  render: "Badge",
  attributes: {
    bgColor: {
      type: String,
    },
    textColor: {
      type: String,
    },
  },
};

export const badgeConfig = {
  tags: {
    badge,
  },
};

export function Badge({ bgColor, textColor, children }) {
  const classes = useStyles();

  return (
    <span
      className={classes.badge}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {children}
    </span>
  );
}
