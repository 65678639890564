import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  FingotiButton,
  FingotiHeader,
  FingotiMarkdown,
} from "@fingoti/components";

import CodeIcon from "@material-ui/icons/Code";
import { cdnUrl } from "../../services/config";
import { useHistory } from "react-router-dom";

const useGridItemStyles = makeStyles((theme) => ({
  itemRoot: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: "1px solid " + theme.palette.greyFour.main,
    backgroundColor: "#F0F0F0",
    textAlign: "center",
  },
  title: {
    fontSize: "1.75rem",
    fontWeight: 200,
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    width: "50%",
  },
  button: {
    width: "100%",
    height: theme.spacing(5),
  },
}));

const SDKGridItem = ({ avaliable = true, title, logoSrc, href }) => {
  const classes = useGridItemStyles();
  const history = useHistory();

  return (
    <Box className={classes.itemRoot}>
      <Typography className={classes.title}>{title}</Typography>
      <img src={logoSrc} className={classes.logo} />
      <div>
        <FingotiButton
          light
          disabled={!avaliable}
          color="primary"
          className={classes.button}
          onClick={() => history.push(href)}
        >
          {avaliable ? "Learn More" : "Coming soon"}
        </FingotiButton>
      </div>
    </Box>
  );
};

const useMainStyles = makeStyles((theme) => ({
  boxPadding: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  itemGrid: {
    marginTop: theme.spacing(6),
  },
}));

export const SDK = () => {
  const classes = useMainStyles();

  const actionButtons = () => {
    return <React.Fragment></React.Fragment>;
  };

  const breadcrumbs = [
    { text: "SDK", link: "/sdk" },
    { text: "Overview", link: "" },
  ];

  return (
    <div className={classes.boxPadding}>
      <FingotiHeader
        breadcrumbs={breadcrumbs}
        sectionIcon={CodeIcon}
        actionButtons={actionButtons()}
      />
      <FingotiMarkdown path="/documentation/developer/sdk/overview.md" />
      <Grid className={classes.itemGrid} container spacing={4}>
        <Grid item xl={2} lg={3} sm={6} xs={12}>
          <SDKGridItem
            title="C Sharp"
            logoSrc={`${cdnUrl}/images/static/csharp.png`}
            href="sdk/csharp"
          />
        </Grid>
        <Grid item xl={2} lg={3} sm={6} xs={12}>
          <SDKGridItem
            title="Java"
            logoSrc={`${cdnUrl}/images/static/java.png`}
            href="sdk/java"
          />
        </Grid>
        <Grid item xl={2} lg={3} sm={6} xs={12}>
          <SDKGridItem
            avaliable={false}
            title="NodeRed"
            logoSrc={`${cdnUrl}/images/static/nodered.png`}
          />
        </Grid>
        <Grid item xl={2} lg={3} sm={6} xs={12}>
          <SDKGridItem
            title="Python"
            logoSrc={`${cdnUrl}/images/static/python.png`}
            href="sdk/python"
          />
        </Grid>
        <Grid item xl={2} lg={3} sm={6} xs={12}>
          <SDKGridItem
            title="TypeScript"
            logoSrc={`${cdnUrl}/images/static/typescript.png`}
            href="sdk/typescript"
          />
        </Grid>
      </Grid>
    </div>
  );
};
