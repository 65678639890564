import { Fragment } from "react";
import { FingotiHeader } from "@fingoti/components";

import Markdoc from "@markdoc/markdoc";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";

import { JSON, jsonConfig } from "../markdoc/components/JSON";
import {
  productLineTabsConfig,
  ProductLineTab,
  ProductLineTabs,
} from "../markdoc/components/ProductLineTabs";
import {
  contextualCalloutConfig,
  ContextualCallout,
} from "../markdoc/components/ContextualCallout";
import { Badge, badgeConfig } from "../markdoc/components/Badge";
import {
  PrereqList,
  PrereqLink,
  PrereqText,
  prereqListConfig,
} from "../markdoc/components/PrereqList";
import { DocImage, docImageConfig } from "../markdoc/components/DocImage";
import { Callout, calloutConfig } from "../markdoc/components/Callout";
import { DocLink, docLinkConfig } from "../markdoc/components/DocLink";
import { InfoHover, infoHoverConfig } from "../markdoc/components/InfoHover";
import { Tabs, Tab, tabsConfig } from "../markdoc/components/Tabs";

import DocTemplate from "../markdoc/DocTemplate";
import {
  RenderSwagger,
  renderSwaggerConfig,
} from "../markdoc/components/RenderSwagger";
import {
  DeviceBadge,
  deviceBadgeConfig,
} from "../markdoc/components/DeviceBadge";
import { InlineCode, codeConfig } from "../markdoc/components/InlineCode";
import { Blockquote, blockquoteConfig } from "../markdoc/components/Blockquote";
import {
  useDocsState,
  docsStateReducerActions,
} from "../../context/DocsStateContext";

export default function References() {
  const {
    state: { breadcrumbs },
    dispatch,
  } = useDocsState();
  const breads = [{ text: "Reference", link: "/" }, ...breadcrumbs];

  const components = {
    Fragment: Fragment,
    RenderSwagger: RenderSwagger,
    JSON: JSON,
    DocImage: DocImage,
    DocLink: DocLink,
    InfoHover: InfoHover,
    Badge: Badge,
    Callout: Callout,
    ContextualCallout: ContextualCallout,
    ProductLineTab: ProductLineTab,
    ProductLineTabs: ProductLineTabs,
    PrereqList: PrereqList,
    PrereqLink: PrereqLink,
    PrereqText: PrereqText,
    Tabs: Tabs,
    Tab: Tab,
    DeviceBadge: DeviceBadge,
    InlineCode: InlineCode,
    Blockquote: Blockquote,
  };

  const transformConfig = {
    nodes: {
      ...jsonConfig.tags,
      ...docImageConfig.tags,
      ...docLinkConfig.tags,
      ...codeConfig.tags,
      ...blockquoteConfig.tags,
    },
    tags: {
      ...productLineTabsConfig.tags,
      ...contextualCalloutConfig.tags,
      ...infoHoverConfig.tags,
      ...badgeConfig.tags,
      ...prereqListConfig.tags,
      ...calloutConfig.tags,
      ...tabsConfig.tags,
      ...renderSwaggerConfig.tags,
      ...deviceBadgeConfig.tags,
    },
  };

  const Header = () => (
    <FingotiHeader
      sectionIcon={SubjectOutlinedIcon}
      breadcrumbs={breads}
      onBreadcrumbClick={(path) => {
        path === "/"
          ? dispatch({ type: docsStateReducerActions.resetSelection })
          : dispatch({
              type: docsStateReducerActions.inlineLinkClick,
              payload: { path: path },
            });
      }}
    />
  );

  return (
    <DocTemplate
      Header={Header}
      components={components}
      transformConfig={transformConfig}
    />
  );
}
