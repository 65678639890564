import { FingotiLoading } from "@fingoti/components";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {
  useDocsState
} from "../../context/DocsStateContext";
import { FingotiMarkdoc } from "./Markdoc";
import DocTreeView from "./DocTreeView";

const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  boxPadding: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    "& > article > h1:first-child": {
      marginTop: 0,
    },
    "& > article > ul > li": {
      marginTop: 8,
      marginBottom: 8,
    },
    "& > article table td, th": {
      textAlign: "left",
      padding: `${theme.spacing(1) / 2}px ${theme.spacing(2)}px`,
    },
    "& > article table": {
      display: "block",
      overflow: "auto"
    },
    "& > article ol > li > ol": {
      listStyleType: "lower-alpha",
    },
    "& > article ol > li > ol > li > ol": {
      listStyleType: "lower-roman",
    },
  },
  tree: {
    borderRight: `1px solid ${theme.palette.greyFour.main}`,
  },
  sticky: {
    position: "sticky",
    top: 112,
  },
}));

export default function DocTemplate({ Header, components, transformConfig }) {
  const classes = useStyles();
  const {
    state: {
      mdUrl,
      tree: { loading, error },
    },
  } = useDocsState();

  return loading ? (
    <div className={classes.loadingRoot}>
      <FingotiLoading />
    </div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <Grid container>
      <DocTreeView />
      <Grid item xs={12} md={10}>
        <div className={classes.boxPadding}>
          <Header />
          {mdUrl && (
            <FingotiMarkdoc
              path={mdUrl}
              components={components}
              transformConfig={transformConfig}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
}
