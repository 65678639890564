import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  docsStateReducerActions,
  useDocsState,
} from "../../../context/DocsStateContext";

const useStyles = makeStyles((theme) => ({
  preqlistRoot: {
    width: "fit-content",
    minWidth: "50%",
    backgroundColor: theme.palette.greyFour.main,
    borderRadius: theme.spacing(1) / 2,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& > h3": {
      marginTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: "auto"
  }
  },
}));

const prereqList = {
  render: "PrereqList",
};

const prereqLink = {
  render: "PrereqLink",
  attributes: {
    link: {
      type: String,
    },
  },
};

const prereqText = {
  render: "PrereqText",
};

export const prereqListConfig = {
  tags: {
    prereqList,
    prereqLink,
    prereqText,
  },
};

export function PrereqText({ children }) {
  return (
    <ListItem>
      <ListItemIcon>
        <FiberManualRecordIcon style={{ fontSize: 14 }} />
      </ListItemIcon>
      <ListItemText>{children?.props?.children}</ListItemText>
    </ListItem>
  );
}

export function PrereqLink({ children, link }) {
  const { dispatch } = useDocsState();

  console.log("children", children);

  return (
    <ListItem
      button
      onClick={() =>
        dispatch({
          type: docsStateReducerActions.inlineLinkClick,
          payload: { path: link },
        })
      }
    >
      <ListItemIcon>
        <LinkOutlinedIcon />
      </ListItemIcon>
      <ListItemText>{children?.props?.children}</ListItemText>
    </ListItem>
  );
}

export function PrereqList({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.preqlistRoot}>
      <h3>Prerequisites list</h3>
      <List dense disablePadding>
        {children}
      </List>
    </div>
  );
}
