import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FingotiNotFound } from "@fingoti/components";

import Layout from "./components/Layout";

import { Home } from "./components/home/Home";
// import { Device } from "./components/device/Device";
// import { Api } from "./components/api/Api";
import { SDK } from "./components/sdk/SDK";

import { cookieSettings } from "./services/config";
import { authService } from "./services/auth.service";
import { useProfileDispatch, useProfileState } from "./context/ProfileContext";
import { ProfileData } from "./context/ProfileData";

import "./main.scss";
import { SDKTypescript } from "./components/sdk/Typescript";
import { SDKPython } from "./components/sdk/Python";
import { SDKCSharp } from "./components/sdk/CSharp";
import { SDKJava } from "./components/sdk/Java";
import { DocsStateContextProvider } from "./context/DocsStateContext";
import Documentation from "./components/documentation/Documentation";
import References from "./components/refrences/References";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { loggedIn, loading } = useProfileState();
//   //get return url query string
//   let params = new URLSearchParams(window.location.search);
//   let returnUrl = params.get("ReturnUrl");

//   //not logged in so redirect to login page with the return url
//   if (!loggedIn & !loading) {
//     console.log("no user");
//     if (returnUrl) {
//       return (
//         <Redirect
//           to={{
//             pathname: "/",
//             search: "?ReturnUrl=" + returnUrl,
//             state: { from: rest.location },
//           }}
//         />
//       );
//     } else {
//       return (
//         <Redirect to={{ pathname: "/", state: { from: rest.location } }} />
//       );
//     }
//   }

//   // authorised so return component
//   return <Route {...rest}>{children}</Route>;
// };

const App = () => {
  const profileData = ProfileData();
  const dispatch = useProfileDispatch();
  const [cookies, setCookie] = useCookies();

  const handleVisibilityChange = (e) => {
    if (document.visibilityState === "visible") {
      console.log("page is vis, checking cookie and LS match");
      authService.findAOCookie().then((aoCookie) => {
        authService.findAppToken().then((appToken) => {
          if (aoCookie !== appToken.oid) {
            authService.getAppToken().then(() => {
              profileData.getData();
            });
          }
        });
      });
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange, false);

  useEffect(() => {
    if (cookies.ActiveOrganisation) {
      authService
        .findAppToken()
        .then((appToken) => {
          let hasExpired =
            Date.now() / 1000 > new Date(appToken.exp) ? true : false;
          if (!hasExpired) {
            if (cookies.ActiveOrganisation !== appToken.oid) {
              authService.getAppToken().then(() => {
                profileData.getData();
              });
            } else {
              dispatch({ type: "LOGGED_IN" });
              profileData.getData();
            }
          } else {
            authService
              .getAppToken()
              .then(() => {
                profileData.getData();
              })
              .catch(() => {
                authService.appLogout();
                dispatch({ type: "LOADED" });
              });
          }
        })
        .catch(() => {
          authService
            .getAppToken()
            .then(() => {
              profileData.getData();
            })
            .catch(() => {
              dispatch({ type: "LOADED" });
            });
        });
    } else {
      dispatch({ type: "LOADED" });
    }
  }, []);

  return (
    <div className="App">
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />

          {/* <Route exact path="/api">
            <Api />
          </Route>

          <Route exact path="/api/:tab">
            <Api />
          </Route> */}

          <Route exact path="/sdk" component={SDK} />

          <Route exact path="/sdk/typescript">
            <SDKTypescript activeMenu={0} />
          </Route>

          <Route exact path="/sdk/python">
            <SDKPython activeMenu={0} />
          </Route>

          <Route exact path="/sdk/csharp">
            <SDKCSharp activeMenu={0} />
          </Route>

          <Route exact path="/sdk/java">
            <SDKJava activeMenu={0} />
          </Route>

          {/* <Route exact path="/hardware">
            <Device />
          </Route>

          <Route exact path="/hardware/:tab">
            <Device />
          </Route> */}

          <Route exact path="/documentation">
            <DocsStateContextProvider structureFile="documentation">
              <Documentation />
            </DocsStateContextProvider>
          </Route>

          <Route exact path="/reference">
            <DocsStateContextProvider structureFile="reference">
              <References />
            </DocsStateContextProvider>
          </Route>

          {/* Keep at bottom */}
          <Route component={FingotiNotFound} />
        </Switch>
      </Layout>
    </div>
  );
};

export default App;
