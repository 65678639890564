import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  blockquoteRoot: {
    background: theme.palette.primary.lighter,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderRadius: theme.spacing(1),
    margin: `${theme.spacing(4)}px 0`
  },
}));

const blockquote = {
  render: "Blockquote",
};

export const blockquoteConfig = {
  tags: {
    blockquote,
  },
};

export function Blockquote({ children }) {
  const classes = useStyles();

  return <div className={classes.blockquoteRoot}>{children}</div>;
}
