import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  def: {
    marginLeft: theme.spacing(3),
  },
}));

const defList = {
  render: "DefList",
};

const def = {
  render: "Def",
};

export const defListConfig = {
  tags: {
    defList,
    def,
  },
};

export function Def({ children }) {
  const classes = useStyles();
  return <span className={classes.def}>{children?.props?.children}</span>;
}

export function DefList({ children }) {
  return <div>{children}</div>;
}
