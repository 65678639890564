import React, { useContext, useEffect, useReducer } from "react";

import { compareArrays } from "../utils/compareArrays";

import useGithubTree from "../hooks/useGithubTree";
import { set } from "lodash";

const initalState = {
  mdUrl: undefined,
  expanded: [],
  selected: 0,
  structureFile: undefined,
  breadcrumbs: [],
  tree: {
    data: undefined,
    loading: true,
    error: false,
  },
};

export const docsStateReducerActions = {
  setFile: "setFile",
  setTree: "setTree",
  setStructureFile: "setStructureFile",
  inlineLinkClick: "inlineLinkClick",
  resetSelection: "resetSelection",
};

export const DocsStateContext = React.createContext();

const handleDocLinkClick = (path, tree, currentState) => {
  let pathParts = path.split("/");
  let structure = tree.data.children;
  let breadcrumbs = [];
  let indexs = [];
  let setBreadcrumb = false;

  for (var i = 0; i < pathParts.length - 1; i++) {
    let builtPath = "";

    for (let j = 0; j < i + 1; j++) {
      builtPath = builtPath + pathParts[j];
      if (j !== i) {
        builtPath = builtPath + "/";
      }
    }

    structure = structure.find((v) => {
      return v.path === builtPath;
    });

    breadcrumbs.push({ text: structure.name, link: structure.path });
    indexs.push(`${structure.index}`);

    structure = structure.children;
  }

  let finalObject = structure.find((v) => v.path === path);

  breadcrumbs.push({ text: finalObject.name, link: finalObject.path });

  if (finalObject.children) {
    indexs.push(`${finalObject.index}`);
  }

  let parentIndex = currentState.expanded.findIndex(
    (el) => el === String(finalObject.index)
  );

  if (parentIndex !== -1) {
    let toRemove = currentState.expanded.length - parentIndex;
    indexs.splice(-Math.abs(toRemove), toRemove);
  }

  if(finalObject.download_url) {
    setBreadcrumb = true;
  } 

  return [
    {
      mdUrl: finalObject.download_url,
      expanded: indexs,
      selected: `${finalObject.index}`,
    },
    setBreadcrumb,
    breadcrumbs,
  ];
};

const docsStateReducer = (state, action) => {
  switch (action.type) {
    case docsStateReducerActions.setTree:
      return { ...state, tree: action.payload };
    case docsStateReducerActions.setFile:
      return { ...state, mdUrl: action.payload.download_url };
    case docsStateReducerActions.setStructureFile:
      return { ...state, structureFile: action.payload.structureFile };
    case docsStateReducerActions.inlineLinkClick:
      let docObject = handleDocLinkClick(
        action.payload.path,
        state.tree,
        state
      );
      return {
        ...state,
        ...docObject[0],
        breadcrumbs: docObject[1] ? docObject[2] : state.breadcrumbs,
        mdUrl: docObject[0].mdUrl ? docObject[0].mdUrl : state.mdUrl,
      };
    case docsStateReducerActions.resetSelection:
      return { ...state, mdUrl: state.tree.data.download_url, breadcrumbs: [], expanded: [], selected: 0 };
    default:
      console.error("Unhandled reducer action");
      return state;
  }
};

export const DocsStateContextProvider = ({ children, structureFile }) => {
  const { loading, error, tree } = useGithubTree(structureFile);
  const [state, dispatch] = useReducer(docsStateReducer, initalState);

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: docsStateReducerActions.setStructureFile,
        payload: {
          structureFile,
        },
      });
      dispatch({
        type: docsStateReducerActions.setTree,
        payload: {
          data: tree,
          loading,
          error,
        },
      });
      dispatch({
        type: docsStateReducerActions.setFile,
        payload: {
          download_url: tree.download_url,
        },
      });
      dispatch({
        type: docsStateReducerActions.resetSelection,
      });
    }
  }, [tree, loading, error, structureFile]);

  return (
    <DocsStateContext.Provider value={{ state, dispatch }}>
      {children}
    </DocsStateContext.Provider>
  );
};

export const useDocsState = () => {
  const ctx = useContext(DocsStateContext);

  if (!ctx) {
    console.error("useDocsStateContext must be used within provider");
  }

  return ctx;
};
