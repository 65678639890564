import { Fragment } from "react";
import { FingotiHeader } from "@fingoti/components";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { JSON, jsonConfig } from "../markdoc/components/JSON";
import {
  productLineTabsConfig,
  ProductLineTab,
  ProductLineTabs,
} from "../markdoc/components/ProductLineTabs";
import {
  contextualCalloutConfig,
  ContextualCallout,
} from "../markdoc/components/ContextualCallout";
import { Badge, badgeConfig } from "../markdoc/components/Badge";
import {
  PrereqList,
  PrereqLink,
  PrereqText,
  prereqListConfig,
} from "../markdoc/components/PrereqList";
import { DocImage, docImageConfig } from "../markdoc/components/DocImage";
import { Callout, calloutConfig } from "../markdoc/components/Callout";
import { DocLink, docLinkConfig } from "../markdoc/components/DocLink";
import { InfoHover, infoHoverConfig } from "../markdoc/components/InfoHover";
import { Tabs, Tab, tabsConfig } from "../markdoc/components/Tabs";
import {
  DefList,
  Def,
  defListConfig,
} from "../markdoc/components/DefinitionList";
import DocTemplate from "../markdoc/DocTemplate";

import {
  useDocsState,
  docsStateReducerActions,
} from "../../context/DocsStateContext";

export default function Documentation() {
  const {
    state: { breadcrumbs },
    dispatch,
  } = useDocsState();
  const breads = [{ text: "Documentation", link: "/" }, ...breadcrumbs];

  const components = {
    Fragment: Fragment,
    JSON: JSON,
    DocImage: DocImage,
    DocLink: DocLink,
    InfoHover: InfoHover,
    Badge: Badge,
    Callout: Callout,
    ContextualCallout: ContextualCallout,
    ProductLineTab: ProductLineTab,
    ProductLineTabs: ProductLineTabs,
    PrereqList: PrereqList,
    PrereqLink: PrereqLink,
    PrereqText: PrereqText,
    Tabs: Tabs,
    Tab: Tab,
    DefList: DefList,
    Def: Def,
  };

  const transformConfig = {
    nodes: {
      ...jsonConfig.tags,
      ...docImageConfig.tags,
      ...docLinkConfig.tags,
    },
    tags: {
      ...productLineTabsConfig.tags,
      ...contextualCalloutConfig.tags,
      ...infoHoverConfig.tags,
      ...badgeConfig.tags,
      ...prereqListConfig.tags,
      ...calloutConfig.tags,
      ...tabsConfig.tags,
      ...defListConfig.tags,
    },
  };

  const Header = () => (
    <FingotiHeader
      sectionIcon={DescriptionOutlinedIcon}
      breadcrumbs={breads}
      onBreadcrumbClick={(path) => {
        path === "/"
          ? dispatch({
              type: docsStateReducerActions.resetSelection,
            })
          : dispatch({
              type: docsStateReducerActions.inlineLinkClick,
              payload: { path: path },
            });
      }}
    />
  );

  return (
    <DocTemplate
      Header={Header}
      components={components}
      transformConfig={transformConfig}
    />
  );
}
